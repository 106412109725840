import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import ProductItem from '../../Components/ProductItem';

function VbRelatedProducts(props) {
  const [dimension] = useOutlet('dimension');
  const {product} = props;
  const [products, setProducts] = React.useState(null);

  const fetchData = React.useCallback(async () => {
    let productIds = [
      product.related_1,
      product.related_2,
      product.related_3,
    ].filter((element) => !!element);

    if (productIds.length === 0) {
      return;
    }

    try {
      let resp = await JStorage.fetchAllDocuments('product', {
        _id: {$in: productIds.map((id) => ({$oid: id}))},
      });

      setProducts(resp);
    } catch (err) {
      console.warn(err);
    }
  }, [product.related_1, product.related_2, product.related_3]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <h2 className="title">相關推薦</h2>
      <div className="container">
        {(products || []).map((product, index) => (
          <ProductItem
            index={index}
            mobile={dimension.rwd === 'mobile'}
            product={product}
            onClick={() => AppActions.navigate(`/product?id=${product.id}`)}
          />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .title {
    margin-bottom: 10px;
    text-align: center;
  }

  & > .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export default VbRelatedProducts;
